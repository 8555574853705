package com.narbase.narnic.trial.medico

import com.narbase.bos.common.Constants
import com.narbase.bos.common.data.AppColors
import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.narnic.trial.utils.centerVertical
import com.narbase.narnic.trial.utils.mediumScreen
import com.narbase.narnic.trial.utils.mediumScreenDivider
import com.narbase.narnic.trial.utils.smallScreen
import kotlinx.browser.window
import org.w3c.dom.events.EventListener

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/25.
 */
class TabsSection(
    private val productLogo: String,
    val tabsContents: List<FeatureTabContentDs>,
    private val backgroundStyle: String
) : Component() {

    var tabNamesViews = mutableMapOf<String, TabNameViewHolder>()

    private lateinit var mainFeaturesTextView: CustomView
    private lateinit var mainFeaturesContent: View
    private lateinit var imageContainer: View
    private lateinit var mainFeaturesImage: ImageView

    private var isTimerStarted = false
    private lateinit var scrollEventListener: EventListener
    var animationTimersIds = mutableListOf<Int>()

    var selectedTab: FeatureTabContentDs? = null
    var timerId: Int? = null

    class TabNameViewHolder(
        val textView: TextView,
        val indicator: View
    )

    override fun onViewCreated(lifecycleOwner: LifecycleOwner) {
        super.onViewCreated(lifecycleOwner)
        restartTimerWhenScrollInto()
        setSelectedTab(tabsContents[0])
    }

    private fun restartTimerWhenScrollInto() {
        if (::scrollEventListener.isInitialized.not()) {
            scrollEventListener = EventListener {
                if (isTimerStarted) {
                    if (::scrollEventListener.isInitialized) window.removeEventListener("scroll", scrollEventListener)
                    return@EventListener
                }

                val bottom = if (::mainFeaturesTextView.isInitialized) {
                    val rect = mainFeaturesTextView.element.getBoundingClientRect()
                    rect.bottom
                } else 0.0
                val isVisible = bottom <= window.innerHeight
                if (isVisible) {
                    restartTimer()
                    isTimerStarted = true
                }
            }
        }

        window.addEventListener("scroll", scrollEventListener)


    }

    override fun View?.getView() = section {
        style {
            width = matchParent
        }
        centerVertical {
            style {
                padding = 16.px
            }
            verticalLayout {
                style {
                    height = wrapContent
                    width = matchParent
                    marginTop = 16.px
                    marginBottom = 16.px
                    padding = 32.px
                    alignSelf = Alignment.Center
                    borderRadius = 30.px
                    background = backgroundStyle
                }


                horizontalLayout {
                    style {
                        width = matchParent
                        marginBottom = 16.px
                        flexWrap = "wrap"
                    }

                    imageView {
                        style {
                            height = 52.px
                        }
                        element.src = productLogo
                        element.alt = "productLogo"
                    }

                    mediumScreenDivider()

                    horizontalLayout {
                        style {
                            flexWrap = "wrap"
                            width = weightOf(1)
                            justifyContent = JustifyContent.End
                            mediumScreen {
                                marginTop = 16.px
                                justifyContent = JustifyContent.Start
                            }
                        }
                        tabsContents.forEach {
                            tabNamesViews[it.tabName] = tabView(it)

                        }
                    }


                }

                horizontalLayout {
                    style {
                        width = matchParent
                        flexWrap = "wrap"
                    }

                    verticalLayout {
                        style {
                            width = weightOf(1)
                            alignSelf = Alignment.Stretch
                            order = "1"
                            mediumScreen {
                                order = "3"
                            }

                        }

                        mainFeaturesTextView = productTitleWithText("")

                        mainFeaturesContent = verticalLayout {
                            style {
                                width = matchParent
                                flexGrow = "1"
                                transition = "all ${tabTransitionSpeedInSeconds}s ease-in-out"
                            }

                        }
                        getStartedButton {
                            marginTop = 32.px
                        }

                    }

                    view {
                        style {
                            width = 32.px
                            order = "2"
                            mediumScreen {
                                width = 100.percent
                            }
                        }
                    }

                    featuresImage()
                }


            }

        }
    }

    private fun LinearLayout.tabView(tabContent: FeatureTabContentDs): TabNameViewHolder {
        var textView: TextView? = null
        var indicator: View? = null
        verticalLayout {
            style {
                marginRight = 16.px
                cursor = "pointer"
                padding = 8.px
                borderRadius = 8.px
                mediumScreen {
                    marginRight = 8.px
                }
                smallScreen {
                    marginRight = 4.px
                }
                hover {
                    backgroundColor = Color(0, 0, 0, 0.05)
                }
            }
            onClick = {
                setSelectedTab(tabContent)
                restartTimer(longDelay = true)
            }
            textView = textView {
                style {
                    fontWeight = "normal"
                    color = AppColors.textDarkGrey
                    transition = "all ${tabTransitionSpeedInSeconds}s ease-in-out"
                    fontSize = 16.px
                    smallScreen {
                        fontSize = 14.px

                    }
                }
                text = tabContent.tabName
            }
            indicator = view {
                style {
                    backgroundColor = AppColors.balsamColor
                    height = 3.px
                    marginTop = 4.px
                    width = matchParent
                    borderRadius = 1.px
                    visibility = "hidden"
                }
            }
        }
        return TabNameViewHolder(textView!!, indicator!!)
    }

    private fun LinearLayout.productTitleWithText(title: String) = h3 {
        style {
            fontWeight = "bold"
            fontSize = 32.px
            marginTop = 32.px
            marginBottom = 16.px
            color = AppColors.narnicVeryDarkColor
            transition = "all ${tabTransitionSpeedInSeconds}s ease-in-out"
            mediumScreen {
                fontSize = 24.px
            }
            smallScreen {
                fontSize = 18.px
            }
        }
        element.innerText = title
    }

    private fun LinearLayout.getStartedButton(customStyle: RuleSet.() -> Unit = {}) {
        a {
            style {
                backgroundColor = AppColors.balsamColor
                borderRadius = 6.px
                paddingRight = 24.px
                paddingLeft = 24.px
                paddingTop = 12.px
                paddingBottom = 12.px
                border = "none"
                color = AppColors.white
                borderColor = AppColors.balsamColor
                boxShadow = "none"
                fontSize = 16.px
                customStyle()
                textDecoration = "none"
            }
            text = "GET STARTED"
            href = Constants.GETTING_STARTED_LINK
        }

    }

    private fun LinearLayout.featuresImage() {
        imageContainer = verticalLayout {
            id = "imageContainer"
            style {
                width = weightOf(1)
                transition = "all ${tabTransitionSpeedInSeconds}s ease-in-out"
                order = "3"
                mediumScreen {
                    order = "1"
                }
            }


            mainFeaturesImage = imageView {
                style {
                    width = matchParent
                    height = 400.px
                    objectFit = "contain"
                    mediumScreen {
                        height = 200.px
                    }
                }
            }
        }
    }

    private fun setSelectedTab(tabContentDs: FeatureTabContentDs) {
        animationTimersIds.forEach { window.clearInterval(it) }
        animationTimersIds.clear()

        listOf(mainFeaturesTextView, mainFeaturesContent, imageContainer).forEach {
            it.element.style.opacity = "0"
        }

        window.setTimeout({
            mainFeaturesTextView.element.innerText = tabContentDs.title

            mainFeaturesContent.clearAllChildren()
            mainFeaturesContent.apply {
                tabContentDs.bulletFeatures.forEach { featureText -> featureListItem(featureText) }
            }

            mainFeaturesImage.element.onload = {
                imageContainer.element.style.opacity = "1"
                asDynamic()
            }
            mainFeaturesImage.element.setAttribute("src", tabContentDs.imagePath)

            listOf(mainFeaturesTextView).forEach {
                it.element.style.opacity = "1"
            }

            window.setTimeout({ mainFeaturesContent.element.style.opacity = "1" }, tabTransitionSpeedInMillis.toInt())
        }, tabTransitionSpeedInMillis.toInt()).also { animationTimersIds.add(it) }


        tabNamesViews.forEach { (tabName, viewHolder) ->
            if (tabName == tabContentDs.tabName) {
                viewHolder.textView.element.style.color = AppColors.balsamColor.toCss()
                viewHolder.textView.element.style.fontWeight = "bold"
            } else {
                viewHolder.textView.element.style.color = AppColors.textDarkGrey.toCss()
                viewHolder.textView.element.style.fontWeight = "unset"
            }
        }
        selectedTab = tabContentDs
    }

    private fun View.featureListItem(featureText: String) {
        horizontalLayout {
            style {
                width = matchParent
                margin = 4.px
            }

            imageView {
                style {
                    height = 24.px
                    margin = 4.px
                }
                element.src = "/public/img/check-mark.svg"
            }

//            view {
//                style {
//                    backgroundColor = AppColors.black
//                    width = 6.px
//                    height = 6.px
//                    borderRadius = 8.px
//                    margin = 4.px
//                }
//            }

            textView {
                text = featureText
                style {
                    width = weightOf(1)
                    color = AppColors.narnicVeryDarkColor
                    fontSize = 16.px
                    margin = 4.px
                    mediumScreen {
                        fontSize = 14.px
                    }
                    smallScreen {
                        fontSize = 12.px
                    }
                }

            }
        }
    }

    private fun selectNextTab() {
        val nextTab = getNextTab()
        setSelectedTab(nextTab)
    }

    private fun getNextTab(): FeatureTabContentDs {
        val selectedTab = selectedTab ?: return tabsContents.first()
        val index = tabsContents.indexOf(selectedTab)
        return if (index == tabsContents.lastIndex) {
            tabsContents.first()
        } else tabsContents[index + 1]
    }

    private fun restartTimer(longDelay: Boolean = false) {
        timerId?.let { window.clearInterval(it) }
        timerId = window.setInterval({
            selectNextTab()
        }, if (longDelay) 20000 else 4000)
    }

    private val tabTransitionSpeedInSeconds = 0.3
    private val tabTransitionSpeedInMillis = 1000 * tabTransitionSpeedInSeconds
}