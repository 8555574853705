package com.narbase.narnic.trial.one

import com.narbase.bos.common.data.AppColors
import com.narbase.bos.common.one.BalsamOnePageRef
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.components.view
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.px
import com.narbase.narnic.trial.common.headerShadowOnScroll
import com.narbase.narnic.trial.medico.FeatureTabContentDs
import com.narbase.narnic.trial.medico.TabsSection

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
object OneLandingPageHydrate {
    fun hydrate(ref: BalsamOnePageRef) {
        console.log("hydrate")
        ref.mainFeaturesTab1?.onClick = { populateMainFeaturesTab(ref, emrTabContent) }
        ref.mainFeaturesTab2?.onClick = { populateMainFeaturesTab(ref, schedulingTabContent) }
        ref.mainFeaturesTab3?.onClick = { populateMainFeaturesTab(ref, reportsTabContent) }
        ref.mainFeaturesTab4?.onClick = { populateMainFeaturesTab(ref, financeTabContent) }

        ref.medicoTabsRefView?.mount(
            TabsSection(
                "/public/img/balsam-medico.png",
                listOf(emrTabContent, schedulingTabContent, reportsTabContent, financeTabContent),
                "linear-gradient(90deg, #E6F6F6a7 0%, #BDEEEEa7 50%, #E2F4F4a7 100%)"
            )
        )
        ref.linkTabsRefView?.mount(
            TabsSection(
                "/public/img/balsam-link.png",
                linkTabs,
                "linear-gradient(90deg, #E6F6F6a7 0%, #E8E3F4a7 50%, #E2F4F4a7 100%)"
            )
        )
        ref.sitesTabsRefView?.mount(
            TabsSection(
                "/public/img/balsam-sites.png",
                sitesTabs,
                "linear-gradient(90deg, #E6F6F6a7 0%, #F4E9E3a7 50%, #E2F4F4a7 100%)"
            )
        )
        ref.mobileTabsRefView?.mount(
            TabsSection(
                "/public/img/balsam-mobile.png",
                mobileTabs,
                "linear-gradient(90deg, #E6F6F6a7 0%, #E6F4E3a7 50%, #E2F4F4a7 100%)"
            )
        )

        headerShadowOnScroll(ref.headerView)
    }


    private fun populateMainFeaturesTab(ref: BalsamOnePageRef, tabContentDs: FeatureTabContentDs) {
        console.log("Populating")
        ref.mainFeaturesTextView?.text = tabContentDs.title
        ref.mainFeaturesContent?.clearAllChildren()
        ref.mainFeaturesContent?.apply {
            tabContentDs.bulletFeatures.forEach { featureText -> featureListItem(featureText) }

        }
        ref.mainFeaturesImage?.element?.setAttribute("src", tabContentDs.imagePath)
    }

    private fun View.featureListItem(featureText: String) {
        horizontalLayout {
            style {
                alignItems = Alignment.Center
                margin = 4.px
            }

            view {
                style {
                    backgroundColor = AppColors.black
                    width = 6.px
                    height = 6.px
                    borderRadius = 8.px
                    margin = 4.px
                }
            }

            textView {
                text = featureText
                style {
                    color = AppColors.black
                    fontSize = 18.px
                    margin = 4.px
                }

            }
        }
    }

    private val emrTabContent = FeatureTabContentDs(
        "Electronic Medical Record",
        "Powerful and flexible\n Electronic Medical Record",
        listOf("Autocomplete for input fields", "Customizable patient history and visit notes forms", "Voice notes"),
        "/public/img/emr.png"
    )
    private val schedulingTabContent = FeatureTabContentDs(
        "Scheduling",
        "Scheduling,\nDone right!", listOf(
            "Appointments reminders to minimize patients no-show.",
            "Access your schedule anytime, anywhere.",
            "SMS to patients when appointment is booked",
            "Allow your patients to book appointmetns online.",
        ), "/public/img/scheduling.png"
    )
    private val reportsTabContent = FeatureTabContentDs(
        "Dashboard & Reports",
        "Detailed reports,\nClearer image", listOf(
            "Intuitive dashboards, to get insights at a glance.",
            "Dive deeper with detailed finance and performance reports",
            "Review your staff performance with performance reports."
        ), "/public/img/dashboard.png"
    )
    private val financeTabContent = FeatureTabContentDs(
        "Finance",
        "Better finance tracking\nThe life line of your practice", listOf(
            "Simplify your accounting, track everything.",
            "Generate insurance reports",
            "Manage expenses, staff payments, revenue share, and more.",
            "Print bills with your clinic logo."
        ), "/public/img/finance.png"
    )

    /*Link tabs*/

    private val linkTabs = listOf(
        FeatureTabContentDs(
            "Online forms",
            "Safe Online forms",
            listOf(
                "Keep your patients safe from the risk of COVID-19 transmission by using online forms. Build multilingual forms easily for your patients and stay safe from paper forms."
            ),
            "/public/img/forms.png"
        ), FeatureTabContentDs(
            "Send invitations",
            "Send forms to your patients ", listOf(
                "Send invitation links for patients to fill in their information through a mobile phone, tablet or computer. The form will then be securely submitted online..",
            ), "/public/img/sms-invite.png"
        ), FeatureTabContentDs(
            "Custom brand",
            "Customized to your brand", listOf(
                "Embrace your brand in your forms.",
                "Add your identity to your forms and set the colors to match your brand.",
                "Add your logo to the patients forms."
            ), "/public/img/theme.png"
        ), FeatureTabContentDs(
            "Exporting",
            "Your data is yours to keep", listOf(
                "Generate a PDF with all submitted patients forms you need and upload the PDF directly to the patient’s record.",
                "Easily export patient forms to a CSV or Excel files.",
                "No more scanning paper forms."
            ), "/public/img/export.png"
        )
    )
    private val sitesTabs = listOf(
        FeatureTabContentDs(
            "Professional Website",
            "Build your clinic website in a trice",
            listOf(
                "Balsam Sites is a complete service that allows service providers to have a presence online.",
                "Let people know about your clinic.",
                "Doctors information and working hours.",
                "Post news and offers."
            ),
            "/public/img/website-build.png"
        ), FeatureTabContentDs(
            "Stylish Templates",
            "Modern and stylish templates ", listOf(
                "It all starts with a great website. Choose one of our carefully crafted templates to present your practice.",
            ), "/public/img/templates.png"
        ), FeatureTabContentDs(
            "Emails",
            "Professional email with your domain name", listOf(
                "Stop using @gmail and @hotmail and start using your own domain name @yourclinic.",
                "Add branding and credibility to your emails."
            ), "/public/img/emails.png"
        ), FeatureTabContentDs(
            "Online booking",
            "Online booking", listOf(
                "Allow patients to book online appointments.",
                "Through your website, you can list all the services and medical staff and their working hours. Patients can then book through your website from their PC or phones."
            ), "/public/img/book-online.png"
        )
    )
    private val mobileTabs = listOf(
        FeatureTabContentDs(
            "Patients App",
            "Let your patients access your clinic services online from their comfort",
            listOf(
                "Create your own App for your patients and let them access your clinic services online from the comfort of their mobile phones.",
                "Give your patients a personal healthcare platform they like using.",
                "Let your patients check your doctors schedule and book online appointments."
            ),
            "/public/img/mobile-app.png"
        ), FeatureTabContentDs(
            "Online booking",
            "Online booking", listOf(
                "Let your patients check your doctors schedule and book online appointments.",
                "Reduce the 'No show' and let patients have the freedom to manage and cancel their appointments.",
                "Appointments reminders."
            ), "/public/img/online-appointment.png"
        ), FeatureTabContentDs(
            "Connect with patients",
            "Create a direct channel with your customers", listOf(
                "Send push notifications for your patients.",
                "Notify your patients with clinic news and special offers."
            ), "/public/img/mobile-news.png"
        ), FeatureTabContentDs(
            "Patient records",
            "Let your patients access their records", listOf(
                "Allow patients to fill clinic forms online.",
                "Patients can access and track their medical records from any device anytime."
            ), "/public/img/app-forms.png"
        )
    )
}