package com.narbase.bos.common.whatsapp

import com.narbase.kunafa.core.components.Anchor
import com.narbase.kunafa.core.components.Button
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.hydration.reference

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2021/09/11.
 */
class WhatsAppPageRef {
    var learnMoreButton by reference<Button>()
    var headerView by reference<View>()
    var startButton by reference<Anchor>()
    var whatsappTabsRefView by reference<View>()
    var curvesRoot by reference<View>()
}
