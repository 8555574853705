package com.narbase.narnic.trial.pages

import com.narbase.bos.common.pages.MedicoEventPageRef
import com.narbase.narnic.trial.common.headerShadowOnScroll

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
object MedicoEventPageHydrate {

    fun hydrate(ref: MedicoEventPageRef) {
        headerShadowOnScroll(ref.headerView)
    }

}