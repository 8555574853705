package com.narbase.narnic.trial.pages

import com.narbase.bos.common.whatsapp.WhatsAppPageRef
import com.narbase.kunafa.core.components.View
import com.narbase.narnic.trial.common.headerShadowOnScroll
import com.narbase.narnic.trial.medico.FeatureTabContentDs
import com.narbase.narnic.trial.medico.MigrationAnimation
import com.narbase.narnic.trial.medico.TabsSection
import kotlinx.browser.window
import org.w3c.dom.asList

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
object WhatsAppPageHydrate {
    fun hydrate(ref: WhatsAppPageRef) {

        ref.whatsappTabsRefView?.mount(
            TabsSection(
                "/public/img/balsam-medico.png",
                listOf(
                    appointmentsTabContent,
                    medicoIntegrationTabContent,
                    marketingTabContent,
                    treatmentRemindersTabContent
                ),
                "linear-gradient(90deg, #E6F6F6a7 0%, #BDEEEEa7 50%, #E2F4F4a7 100%)"
            )
        )

        headerShadowOnScroll(ref.headerView)
        hydrateMigrationAnimation(ref.curvesRoot)
    }

    private fun hydrateMigrationAnimation(curvesRoot: View?) {
        curvesRoot ?: return
//        console.log("curvesRoot.element.getElementsByTagName(svg)")
//        console.log(curvesRoot.element.getElementsByTagName("svg"))
        val list = curvesRoot.element.getElementsByClassName("migration-curve").asList()

        val migrationItems = curvesRoot.element.getElementsByClassName("migration-item").asList()
        console.log(migrationItems)

        list.forEachIndexed { curveIndex, element ->

            (1..4).forEach { migrationIconIndex ->
                val speedFactor = 3.5
                val numberOfIcons = 4
                window.setTimeout({
                    val original = migrationItems[migrationIconIndex]
                    val item = original.cloneNode(deep = true)
                    original.parentElement?.appendChild(item)
                    item.asDynamic().style.opacity = "1"
                    MigrationAnimation(numberOfIcons * 1000 * speedFactor, 0, element, item).start()
                }, ((curveIndex + migrationIconIndex) * 1000 * speedFactor).toInt())
            }

        }
    }


    private val appointmentsTabContent = FeatureTabContentDs(
        "Appointments management",
        "Your 24/7 Virtual Scheduling Assistant!",
        listOf(
            "Effortless Booking: Patients can easily schedule, reschedule, or cancel appointments with a few simple messages.",
            "Instant Notifications: Send real-time reminders and confirmations directly to patients on WhatsApp.",
            "Always Available: Allow patients to manage their appointments anytime, eliminating the need to wait for office hours."
        ),
        "/public/img/whatsapp/appointment_booking.png"
    )
    private val medicoIntegrationTabContent = FeatureTabContentDs(
        "Medico integration",
        "Seamless Balsam Medico EMR Integration", listOf(
            "Real-Time Syncing: Automatically updates patient appointments and schedules in Balsam Medico EMR, ensuring accuracy and preventing double-bookings.",
            "Unified Patient Records: Keeps all appointment details and patient interactions in one place, enhancing clinic efficiency and patient care continuity."
        ), "/public/img/whatsapp/medico-integration.png"
    )
    private val marketingTabContent = FeatureTabContentDs(
        "Marketing messages",
        "Targeted Patient Messaging", listOf(
            "Marketing Campaigns: Send promotional messages about new services, special offers, and health tips directly to patients' WhatsApp, increasing engagement and clinic visibility.",
            "Special Occasions and Announcements: Notify patients about holiday hours, special events, or important updates, ensuring they are always informed and connected with your clinic.",
        ), "/public/img/whatsapp/marketing-message.png"
    )
    private val treatmentRemindersTabContent = FeatureTabContentDs(
        "Treatment Reminders",
        "Automatic Treatment Reminders", listOf(
            "Proactive Appointment Prompts: Automatically remind patients to schedule their next treatment sessions, ensuring they stay on track with their healthcare plan.",
            "Consistency in Care: Encourage timely bookings for follow-up treatments, enhancing patient outcomes and maintaining consistent care.",
        ), "/public/img/whatsapp/treatment_notifications.png"
    )
}