package com.narbase.bos.common.pages

import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.hydration.reference

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/09/22.
 */
class MedicoEventPageRef {
    var headerView by reference<View>()

}