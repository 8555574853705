package com.narbase.narnic.trial.medico

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
class FeatureTabContentDs(
 val tabName: String,
 val title: String,
 val bulletFeatures: List<String>,
 val imagePath: String
)