package com.narbase.narnic.trial.one

import com.narbase.bos.common.one.BalsamOnePageRef
import com.narbase.bos.common.data.AppColors
import com.narbase.bos.common.one.ThankYouPageRef
import com.narbase.kunafa.core.components.View
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.components.view
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.px
import com.narbase.narnic.trial.medico.FeatureTabContentDs
import com.narbase.narnic.trial.medico.TabsSection
import kotlinx.browser.window

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
object ThankYouPageHydrate {
    fun hydrate(ref: ThankYouPageRef) {
        ref.backToHome?.onClick = {
            window.top.location.href = "http://balsam.narbase.com/"
            asDynamic()
        }
    }


}