package com.narbase.narnic.trial.medico

import kotlinx.browser.window
import kotlin.js.Date
import kotlin.properties.Delegates

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/03/06.
 */
class MigrationAnimation(
    private val duration: Double,
    private val restartDelay: Int,
    private val curve: dynamic,
    private val dot: dynamic
) {
    private var startTime by Delegates.notNull<Double>()

    fun start() {
        startTime = Date.now()
        window.requestAnimationFrame { run() }
    }

    private fun run() {
        val lengthPercentage = minOf((Date.now() - this.startTime) / this.duration, 1.0)

        if (lengthPercentage < 1) {
            window.requestAnimationFrame { run() }
        } else {
            this.onFinish()
        }
        moveDot(lengthPercentage)
    }

    private fun onFinish() {
        window.setTimeout({ start() }, restartDelay);
    }

    private fun moveDot(length: Double) {
        val totalLength = curve.getTotalLength() as Double
        val p = curve.getPointAtLength(length * totalLength)
        dot.setAttribute("transform", "translate(${p.x}, ${p.y})");

//        dot.style.transform = "translate(${p.x}mm, ${p.y}mm)"

    }
}